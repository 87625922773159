import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

export interface GetTraderDetailsParams extends PaginationRequestParams {
  id: string
}

export interface GetTraderHoldingsParams {
  id: string
}

export interface GetTradersParams extends PaginationRequestParams {
  name?: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export const createTrader = (name: string) => {
  return axios.post('/traders', { name })
}

export const getTrader = (id: string) => {
  return axios.get(`/traders/${id}`)
}

export const getTraderHistoryCSVUrl = (id: string) => {
  return `/traders/${id}/history`
}

export const getTraderHistory = (params: GetTraderDetailsParams) => {
  const { id, ...rest } = params
  const querystring = qs.stringify(rest, { indices: false })
  return axios.get(`/traders/${id}/history?${querystring}`)
}

export const getTraderAddressesCSVUrl = (params: GetTraderDetailsParams) => {
  const { id } = params
  return `/traders/${id}/addresses`
}

export const getTraderAddresses = (params: GetTraderDetailsParams) => {
  const { id, ...rest } = params
  const querystring = qs.stringify(rest, { indices: false })
  return axios.get(`/traders/${id}/addresses?${querystring}`)
}

export const getTraderAddressesForToken = (id: string, tokenId: string) => {
  return axios.get(`/traders/${id}/grantable-addresses/${tokenId}`)
}

export const getTraderWhitelistCSVUrl = (params: GetTraderDetailsParams) => {
  const { id } = params
  return `/traders/${id}/whitelist`
}

export const getTraderWhitelist = (params: GetTraderDetailsParams) => {
  const { id, ...rest } = params
  const querystring = qs.stringify(rest, { indices: false })
  return axios.get(`/traders/${id}/whitelist?${querystring}`)
}

export const getTraderTokens = (params: GetTraderDetailsParams) => {
  const { id, ...rest } = params
  const querystring = qs.stringify(rest, { indices: false })
  return axios.get(`/traders/${id}/whitelist?${querystring}`)
}

export const getTradersCSVUrl = (params: GetTradersParams) => {
  const { limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/traders?${querystring}`
}

export const getTraders = (params: Partial<GetTradersParams>) => {
  const { sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/traders?${querystring}`)
}

export const getAllTradersByName = (name?: string) => {
  const params = {
    // offset: 0,
    // limit: 100,
    name: name || undefined,
  }
  return getTraders(params)
}

export const getTraderHoldingsCSVUrl = (id: string) => {
  return `/traders/${id}/holdings`
}

export const getTraderHoldings = (params: GetTraderHoldingsParams) => {
  const { id } = params
  return axios.get(`/traders/${id}/holdings`)
}

export const archiveTrader = (id: string) => {
  return axios.delete(`/traders/${id}`)
}

export const reactivateTrader = (id: string) => {
  return axios.put(`/traders/${id}/reactivate`)
}
