"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTERNAL_TOKENS_PROTOCOLS = exports.EVM_CHAIN_PROTOCOLS = exports.ChainProtocol = void 0;
var ChainProtocol;
(function (ChainProtocol) {
    ChainProtocol["Algorand"] = "ALGORAND";
    ChainProtocol["Arbitrum"] = "ARBITRUM";
    ChainProtocol["Ethereum"] = "ETHEREUM";
    ChainProtocol["Etherlink"] = "ETHERLINK";
    ChainProtocol["Hedera"] = "HEDERA";
    ChainProtocol["Polygon"] = "POLYGON";
    ChainProtocol["XDC"] = "XDC";
    ChainProtocol["XRP_Ledger"] = "XRP_LEDGER";
})(ChainProtocol || (exports.ChainProtocol = ChainProtocol = {}));
exports.EVM_CHAIN_PROTOCOLS = [
    ChainProtocol.Arbitrum,
    ChainProtocol.Ethereum,
    ChainProtocol.Etherlink,
    ChainProtocol.Polygon,
    ChainProtocol.XDC,
];
exports.EXTERNAL_TOKENS_PROTOCOLS = [
    ChainProtocol.Arbitrum,
    ChainProtocol.Ethereum,
    ChainProtocol.Etherlink,
    ChainProtocol.Polygon,
    ChainProtocol.Hedera,
    ChainProtocol.XDC,
];
