import { ChainProtocol, OrganizationAccountType } from '@archax/shared-types'
import { Box, CircularProgress, Link, List, ListItem, ListItemText, Typography, styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getOrganizationNativeBalances } from '../../api/organizations'
import ARBLogo from '../../assets/currency-arb-logo.svg'
import XTZLogo from '../../assets/currency-xtz-logo.svg'
import ETHLogo from '../../assets/currency-eth-logo.svg'
import HbarLogo from '../../assets/currency-hbar-logo.svg'
import MaticLogo from '../../assets/currency-matic-logo.svg'
import XDCLogo from '../../assets/currency-xdc-logo.png'
import XRPLogo from '../../assets/currency-xrp-logo.svg'
import { useGlobalContext } from '../../context'
import nativeCurrencyFormatter from '../../util/native-currency-formatter'
import onApiError from '../../util/on-api-error'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import copyToClipboard from '../../util/copy-to-clipboard'
import { toast } from 'react-toastify'

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  div: {
    display: 'flex',
    flexDirection: 'column',
  },
  span: {
    lineHeight: '100%',
  },
}))

const CHAIN_CURRENCY_ICON = {
  [ChainProtocol.Arbitrum]: ARBLogo,
  [ChainProtocol.Ethereum]: ETHLogo,
  [ChainProtocol.Etherlink]: XTZLogo,
  [ChainProtocol.Hedera]: HbarLogo,
  [ChainProtocol.Polygon]: MaticLogo,
  [ChainProtocol.XDC]: XDCLogo,
  [ChainProtocol.XRP_Ledger]: XRPLogo,
}

const AccountsBalances: React.FunctionComponent = () => {
  const {
    state: { user },
  } = useGlobalContext()

  const { isLoading, data } = useQuery(
    ['organization-native-balances'],
    () => getOrganizationNativeBalances(user!.organizationId),
    {
      retry: false,
      onError: onApiError,
      cacheTime: 0,
    },
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Box>
      <List>
        {data?.data.data.map((chain) => (
          <ListItem key={chain.protocol}>
            <img
              alt={`${chain.protocol} icon`}
              src={
                CHAIN_CURRENCY_ICON[
                  chain.protocol as
                    | ChainProtocol.Arbitrum
                    | ChainProtocol.Ethereum
                    | ChainProtocol.Etherlink
                    | ChainProtocol.Polygon
                    | ChainProtocol.Hedera
                    | ChainProtocol.XDC
                    | ChainProtocol.XRP_Ledger
                ]
              }
              width={24}
              height={24}
            />
            <StyledListItemText sx={{ ml: 1 }}>
              {chain.address && (
                <>
                  <Typography>
                    {nativeCurrencyFormatter(chain.balance as string)} {chain.currencySymbol}
                  </Typography>
                  <div>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      <small>{OrganizationAccountType[chain.type as keyof typeof OrganizationAccountType]}</small>
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {chain.address.length > 12
                        ? `${chain.address.substring(0, 7)}...${chain.address.slice(-5)}`
                        : chain.address}{' '}
                      <ContentCopyIcon
                        onClick={() => {
                          copyToClipboard(chain.address as string)
                          toast.success('Address copied')
                        }}
                        sx={{ fontSize: 12, cursor: 'pointer' }}
                      />{' '}
                      <Link href={chain.explorerLink!} rel="noreferrer" target="_blank" sx={{ color: 'inherit' }}>
                        <OpenInNewIcon sx={{ fontSize: 12, cursor: 'pointer' }} />
                      </Link>
                    </Typography>
                  </div>
                </>
              )}
              {!chain.address && <Typography color="error.dark">Account not set up</Typography>}
            </StyledListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default AccountsBalances
