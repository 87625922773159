import { LoginResponse, User } from '@archax/shared-types'
import { Dispatch, SetStateAction } from 'react'
import { axios } from '../../api/axios'
import * as Sentry from '@sentry/react'

export enum AppLocalStorage {
  USER = 'user',
}

interface AuthState {
  isLoggedIn: boolean
  user: null | User
  errorMessage: null | string
  isLoading: boolean
  accessTokenExpiresAtInMs?: number
}

const initialState: AuthState = { isLoggedIn: false, isLoading: true, user: null, errorMessage: null }

const authContext = {
  state: initialState,
  actions: {
    setCredentials: (setState: Dispatch<SetStateAction<{}>>, payload: LoginResponse): void => {
      const { user, accessToken } = payload
      const accessTokenExpiresAtInMs = JSON.parse(window.atob(accessToken.split('.')[1])).exp * 1000
      localStorage.setItem(AppLocalStorage.USER, JSON.stringify(user))
      setState((state) => ({ ...state, isLoggedIn: true, user, accessTokenExpiresAtInMs }))
      Sentry.setUser({ id: user.id, email: user.email })
    },
    logout: async (setState: Dispatch<SetStateAction<{}>>): Promise<void> => {
      localStorage.removeItem(AppLocalStorage.USER)
      await axios.post('/logout')
      setState((state) => ({ ...state, ...initialState, isLoading: false, accessTokenExpiresAtInMs: null }))
      Sentry.setUser(null)
    },
    getUser: async (setState: Dispatch<SetStateAction<{}>>): Promise<void> => {
      try {
        setState((state) => ({ ...state, isLoading: true }))
        const response = await axios.get('/me')
        const accessTokenExpiresAtInMs = response.data.accessTokenExpiresAt * 1000

        setState((state) => ({
          ...state,
          isLoading: false,
          isLoggedIn: true,
          user: response.data,
          accessTokenExpiresAtInMs: accessTokenExpiresAtInMs ? accessTokenExpiresAtInMs : null,
        }))
      } catch (error) {
        setState((state) => ({ ...state, isLoading: false, isLoggedIn: false, user: null }))
      }
    },
  },
}

export default authContext

// Define types for actions to be included in context
export const authActionsDefinitions = {
  setCredentials: (payload: LoginResponse): void => {},
  logout: (): void => {},
  getUser: (): void => {},
}
