import {
  ChainProtocol,
  Holding,
  PriceType,
  READABLE_DATE_FORMAT,
  READABLE_PRICE_TYPE,
  TABLE_HEADER_NAMES,
  Token,
  TokenType,
} from '@archax/shared-types'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadCSV } from '../../../api/axios'
import { getTokenCapTable, getTokenCapTableCSVUrl } from '../../../api/tokens'
import { ClientSideGrid } from '../../../components/ClientSideGrid'
import Dialog from '../../../components/Dialog/Dialog'
import { useGlobalContext } from '../../../context'
import { isFooterLevel } from '../../../util/ag-grid'
import currencyFormatter from '../../../util/currency-formatter'
import onApiError from '../../../util/on-api-error'
import tokenBalanceFormatter from '../../../util/token-balance-formatter'
import { isEVMProtocol } from '../../../util/token-options'
import { hasUserRole } from '../../../util/user-roles'
import { holderValueFormatter, totalValueFormatter } from '../utils'
import { AddPriceForm } from './AddPriceForm'
import DistributionModal from './DistributionModal'
import { PoolTokenValue } from './PoolTokenValue'
import * as Sentry from '@sentry/react'

const DISTRIBUTABLE_TOKEN_TYPES = [TokenType.Pool, TokenType.Regular]

interface TokenCapTableProps {
  token: Token
}
function TokenCapTable({ token }: TokenCapTableProps) {
  const { id, currency, createdAt, decimals, tokenType } = token
  const [date, setDate] = useState<null | Dayjs>(null)
  const [dateQueryKey, setDateQueryKey] = useState<null | Dayjs>(date)
  const [showAddPriceDialog, setShowAddPriceDialog] = useState(false)

  const {
    state: { user },
  } = useGlobalContext()

  const timestamp = date ? date.unix() : null
  const { isLoading, data, refetch } = useQuery(
    ['token-cap-table', id, dateQueryKey],
    () =>
      getTokenCapTable({
        id,
        timestamp,
      }),
    {
      retry: false,
      onError: onApiError,
    },
  )
  const { t } = useTranslation()

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'TokenCapTable',
      message: 'TokenCap table loaded',
      data: { token, timestamp },
    })
  }, [])

  useEffect(() => {
    const refetchTimeout = setTimeout(() => {
      setDateQueryKey(date)
    }, 1000)

    Sentry.addBreadcrumb({
      category: 'TokenCapTable',
      message: 'TokenCap date changed',
      data: { token, date },
    })

    return () => clearTimeout(refetchTimeout)
  }, [date])

  const { price, data: holdings, tokenDistribution, totals } = data?.data || {}

  const columnDefs: ColDef<Holding>[] = useMemo(
    () => [
      {
        field: 'holderName',
        headerName: t('headers.common.holderName'),
        flex: 1,
        minWidth: 130,
        sortable: false,
        cellRenderer: (params: ValueGetterParams<Holding>) => {
          if (isFooterLevel(params)) {
            return <strong>{t('general.labels.total')}</strong>
          }
          return params.data?.holderName
        },
      },
      {
        field: 'traderAddressName',
        headerName: t('headers.common.holderAddressName'),
        flex: 1,
        minWidth: 450,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Holding>): string | undefined => {
          if (!isFooterLevel(params)) {
            return `${params.data?.holderAddressName} (${params.data?.holderAddress})`
          }
        },
        tooltipValueGetter: (params) => {
          if (!isFooterLevel(params)) {
            return `${params.data?.holderAddressName} (${params.data?.holderAddress})`
          }
        },
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 50,
        sortable: false,
        cellRenderer: (params: ValueGetterParams<Holding>) => {
          if (isFooterLevel(params)) {
            return <strong>{tokenBalanceFormatter(totals?.balance || '0', decimals)}</strong>
          }
          return tokenBalanceFormatter(params.data?.balance || '0', decimals)
        },
      },
      {
        field: 'value',
        headerName: `${TABLE_HEADER_NAMES.common.value} ${currency ? `(${currency})` : ''}`,
        flex: 1,
        minWidth: 100,
        sortable: false,
        cellRenderer: (params: ValueGetterParams<Holding>) => {
          if (isFooterLevel(params)) {
            return <strong>{totalValueFormatter(totals?.value || '0')}</strong>
          }
          return holderValueFormatter({
            holderValue: params.data?.value || '0',
          })
        },
      },
    ],
    [currency, totals],
  )

  const handleCSVDownload = () => downloadCSV(getTokenCapTableCSVUrl({ id, timestamp }))

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: holdings,
      domLayout: 'autoHeight',
      defaultColDef: { resizable: true },
      groupIncludeTotalFooter: true,
    }),
    [columnDefs, holdings],
  )

  const isPoolToken = tokenType === TokenType.Pool
  const showDistribute =
    ((hasUserRole(user) && isEVMProtocol(token.standard.chain.protocol)) ||
      token.standard.chain.protocol === ChainProtocol.Hedera) &&
    DISTRIBUTABLE_TOKEN_TYPES.includes(token.tokenType)
  const showPrice = !isPoolToken

  return (
    <div>
      <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Box marginBottom={2}>
          <DateTimePicker
            format={READABLE_DATE_FORMAT}
            value={date ?? dayjs(date)}
            label="Date"
            minDateTime={dayjs(createdAt)}
            onChange={(newDate) => {
              if (!newDate || !newDate?.isValid()) {
                return
              }
              setDate(newDate)
            }}
            slotProps={{ textField: { variant: 'standard', fullWidth: true, style: { marginBottom: 4 } } }}
          />
        </Box>
        <Box>
          {showDistribute && (
            <DistributionModal
              token={token}
              btnOptions={{
                variant: 'contained',
                disabled: token.hasExternalOwner,
                sx: {
                  ':not(:last-child)': {
                    marginRight: '10px',
                  },
                },
              }}
            />
          )}
          {showPrice && (
            <Button
              data-testid="taken-cap-table__add-price-button"
              variant="contained"
              onClick={() => {
                setShowAddPriceDialog(true)
              }}
              disabled={token.hasExternalOwner}
            >
              Add price
            </Button>
          )}
        </Box>
      </Box>

      {showPrice && !isLoading && data && (
        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
          <Grid item xs={8}>
            <TableContainer sx={{ '& th': { border: 0 }, '& td': { border: 0 } }}>
              <Table aria-label="cap table">
                <TableHead>
                  <TableRow>
                    <TableCell>Effective price date</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: '#F8FBFD' }}>
                  <TableRow>
                    <TableCell>
                      <Typography>{price ? dayjs(price.effectiveDate).format(READABLE_DATE_FORMAT) : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {price ? currencyFormatter(price.price, { prefix: currency, decimals: 2 }) : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{price ? READABLE_PRICE_TYPE[price.type as PriceType] : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{price && price.notes ? price.notes : '-'}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      )}

      {isPoolToken && !isLoading && data && (
        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
          <PoolTokenValue tokenId={id} tokenDistribution={tokenDistribution} totals={totals} handleRefresh={refetch} />
        </Box>
      )}

      {isLoading && <CircularProgress />}
      {!isLoading && data && (
        <>
          <Box
            paddingLeft={0}
            maxWidth="xl"
            flexDirection={'row'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            marginTop={4}
          >
            <Typography align="left" variant="h5">
              Cap table
            </Typography>
          </Box>

          <Box sx={{ width: '100%', marginBottom: 3 }}>
            {holdings.length === 0 && <Typography>No holdings</Typography>}
            {holdings.length > 0 && (
              <ClientSideGrid gridOptions={gridOptions} handleRefresh={refetch} handleCSVDownload={handleCSVDownload} />
            )}
            <Divider />
          </Box>
        </>
      )}
      <Dialog title={'Add price'} onClose={() => setShowAddPriceDialog(false)} open={showAddPriceDialog}>
        <AddPriceForm
          onClose={() => setShowAddPriceDialog(false)}
          onSuccess={() => {
            refetch()
            setShowAddPriceDialog(false)
          }}
          tokenId={id}
          minEffectiveDateTime={createdAt}
          currency={currency}
        />
      </Dialog>
    </div>
  )
}

export default TokenCapTable
