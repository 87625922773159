"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    general: {
        labels: {
            total: 'Total',
            reserve: 'Reserve',
            treasury: 'Treasury',
            holder: 'Holder',
        },
        yes: 'Yes',
        no: 'No',
        true: 'True',
        false: 'False',
        properties: {
            name: 'Name',
            notes: 'Notes',
            symbol: 'Symbol',
            version: 'Version',
            currency: 'Currency',
            decimals: 'Decimals',
            protocol: 'Protocol',
            standard: 'Standard',
            amount: 'Amount',
            addresses: 'Addresses',
            tokenType: 'Token type',
            privacyEnabled: 'Privacy is enabled',
            kycEnabled: 'Whitelist is enabled',
            isKYCable: 'Whitelist is enabled',
            blacklistEnabled: 'Blacklist is enabled',
            isBlacklistable: 'Blacklist is enabled',
            numberToCreate: 'Number to create',
            traderAddressId: 'Trader address',
            tempDocuments: 'Documents',
        },
        protocols: {
            ALGORAND: 'Algorand',
            ARBITRUM: 'Arbitrum',
            ETHEREUM: 'Ethereum',
            ETHERLINK: 'Etherlink',
            HEDERA: 'Hedera',
            POLYGON: 'Polygon',
            XDC: 'XDC',
            XRP_LEDGER: 'XRP Ledger',
        },
        standards: {
            ASA: 'Standard Assets',
            ERC20: 'ERC20',
            ERC721: 'ERC721',
            ERC20_POOL_TOKEN: 'ERC20 Pool Token',
            FUNGIBLE_COMMON: 'Fungible',
            NON_FUNGIBLE_UNIQUE: 'Non Fungible',
            FUNGIBLE_COMMON_POOL: 'Fungible Pool Token',
        },
        tokenTypes: {
            REGULAR: 'Regular',
            POOL: 'Pool',
        },
    },
    holder_one: 'Holder',
    holder_other: 'Holders',
    holder: {
        createNew: 'Create new holder',
        managementScreen: 'Holders Management',
        address: 'Holder address',
        toast: {
            reactivate: 'Holder reactivated',
            archive: 'Holder archived',
            removeAddressFromWhitelist: 'Remove holder address request sent for approval',
            created: 'Holder created',
        },
        dialog: {
            reactivate: 'Are you sure you want to reactivate the holder?',
            archive: 'Are you sure you want to archive the holder?',
            removeAddressFromWhitelist: 'Remove holder address from token whitelist',
            creation: 'Holder creation',
        },
        forms: {
            selectHolder: 'Select Holder',
            selectManageHolder: 'Select the holder you would like to manage',
        },
        errors: {
            missingAddressHolderToWhitelist: 'There are no addresses for this holder that can be whitelisted.',
            uniqueHolderName: 'This holder name is already used',
        },
    },
    standards: {
        listStandards: {
            title: 'EVM standards',
        },
        buttons: {
            deployImplementation: 'Deploy Implementation',
            deployImplementationHelper: 'This implementation contract will be used on all the tokens that are created with this standard',
        },
        dialogs: {
            deployImplementation: 'Deploy implementation contract?',
        },
    },
    headers: {
        common: {
            type: 'Type',
            value: 'Value',
            protocol: 'Protocol',
            tokenName: 'Token name',
            underlyingTokenName: 'Underlying token',
            standard: 'Standard',
            symbol: 'Symbol',
            balance: 'Balance',
            holderAddress: 'Holder address',
            holderAddressName: 'Holder address aame',
            holderName: 'Holder name',
            name: 'Name',
            version: 'Version',
            status: 'Status',
            privacy: 'Privacy enabled',
        },
    },
    requiredChecks: {
        genericErrorMessage: 'An error ocurred while trying to perform the check',
        distributionFundsCheck: {
            name: 'Funds verification',
            description: 'Ensure sufficient funds are available for distribution.',
            actionRequired: 'Please ensure that the account balance meets the required minimum of {{distributionTotal}} to proceed with the operation.',
        },
        distributionGasCheck: {
            name: 'Gas verification',
            description: 'Ensure sufficient gas is available for the for the entire distribution process.',
            actionRequired: 'Please ensure that the account balance meets the required minimum of {{gasTotalCost}} to proceed with the operation.',
        },
        distributionAssociatedTokenCheck: {
            name: 'Associated token',
            description: 'Ensure the elegible accounts for the distribution has associated the token.',
            actionRequired: 'Please ensure that the accounts {{accounts}} are associated with the distribution token {{tokenSymbol}}.',
        },
        changeOwnerAssociatedTokenCheck: {
            name: 'Associated token',
            description: 'Ensure the new owner has associated the token.',
            actionRequired: 'Please ensure that the new owner has associated the token to proceed with the operation.',
        },
        grantKycAssociatedTokenCheck: {
            name: 'Associated token',
            description: 'Ensure the account to be KYCed has associated the token.',
            actionRequired: 'Please ensure that account to be KYCed has associated the token to proceed with the operation.',
        },
        grantKycAssociatedTokenPoolTokenCheck: {
            name: 'Associated token',
            description: 'Ensure the token is registered in the pool token contract.',
            actionRequired: 'Register the token in the pool token contract to proceed with the operation. The pool token will then be automatically associated with the token.',
        },
        changeOwnerTransactionSignatureCheck: {
            name: 'Transaction signature',
            description: 'Ensure the new owner has signed the transaction.',
            actionRequired: 'Please ensure that the new owner has signed the transaction to proceed with the operation. Download the serialized transaction and have the new owner sign it. Attach the signed transaction to the request.',
        },
        trustLineCheck: {
            name: 'Trust Line',
            description: 'Ensure the holder has a trust line with the token.',
            actionRequired: 'Please ensure that the holder has a trust line with the token to proceed with the operation.',
        },
        fundsTrustLineCheck: {
            name: 'Funds for Trust Line',
            description: 'Ensure the {{type}} has at least 2 XRP to create a trust line.',
            actionRequired: 'Please add {{additionalFunds}} XRP so that the {{type}} has at least 2 XRP to create a line of trust with the token to proceed with the operation.',
        },
    },
    errors: {
        token: {
            unexpectedBalanceError: 'An unexpected error occurred while trying to retrieve the token balance. Please try again later.',
        },
    },
    adminApproval: {
        operationDetailsModal: {
            title: 'Operation details',
            noData: 'No operation data available',
            keyLabel: 'Key',
            valueLabel: 'Value',
        },
    },
    token: {
        history: {
            status: {
                approved: 'Approved',
                notApproved: 'Not approved',
                error: 'Error',
            },
        },
        documents: {
            title: 'Documents',
            actions: {
                addNew: 'Add new document',
                preview: 'Preview',
                download: 'Download',
            },
        },
    },
};
